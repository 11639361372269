import {
  AppointmentsStatus,
  BookingRequest,
} from "../../services/bookingRequest/bookingRequestModel";
import { User } from "../../services/user/userModel";
import { CAL_BASE_URL, TISIO_FEE_PERCENTAGE } from "../../services/config";
import { Link } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { formatDate } from "../../react-helpers/date";
import DisplayPrice from "../DisplayPrice";
import Icon from "../Icon";

const AppointmentsTable = ({
  bookingRequests,
  loggedUserId,
  status,
  minimal = false,
}: {
  bookingRequests: BookingRequest[];
  loggedUserId: User["id"];
  status: AppointmentsStatus;
  minimal?: boolean;
}) => {
  // TODO : make it a helper, and use it everywhere
  const addFee = useCallback(
    (price: BookingRequest["price"], fee: BookingRequest["fee"]) =>
      Number(price) * (1 + (fee ?? parseInt(TISIO_FEE_PERCENTAGE, 10)) / 100),
    [],
  );

  const isSender = useCallback(
    (request: BookingRequest) => request.sender!.user.id === loggedUserId,
    [loggedUserId],
  );

  const hasCampaigns = useMemo(
    () => bookingRequests.some((request) => request.campaignId !== null),
    [bookingRequests],
  );

  return (
    <div>
      <div className="section_title">Rendez-vous {status}</div>

      <div className="cblock table-scroll">
        <table className="table">
          <thead>
            <tr>
              <th>Le</th>
              <th>Avec</th>
              {minimal === false && (
                <>
                  {(status === AppointmentsStatus.TO_COME ||
                    status === AppointmentsStatus.ONGOING) && (
                    <th>Lien visio</th>
                  )}
                  {hasCampaigns && <th>Campagne</th>}
                  <th>Prix&nbsp;(HT)</th>
                  <th>Rémunération</th>
                </>
              )}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {bookingRequests.map((request) => (
              <tr key={request.id}>
                <td className="--shrink">
                  {/* French date format : Lundi 8 juillet 2024 - 17:00 */}
                  <strong>
                    {formatDate(new Date(request.booking!.startTime), "PPPp")}
                  </strong>
                </td>
                <td className="--shrink">
                  {isSender(request)
                    ? request.recipient?.user.displayName ??
                      `${request.extFirstname} ${request.extLastname?.[0]}.`
                    : request.sender!.user.displayName}
                </td>
                {minimal === false && (
                  <>
                    {(status === AppointmentsStatus.TO_COME ||
                      status === AppointmentsStatus.ONGOING) && (
                      <td>
                        <a
                          href={`${CAL_BASE_URL}/video/${request.booking?.uid}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {`${CAL_BASE_URL}/video/${request.booking?.uid}`}
                        </a>
                      </td>
                    )}
                    {/* TODO: uncomment when other communities will be create */}
                    {/* <td className="--shrink">
                      <div className="ui-row">
                        <span className="tag">
                          {request.sender?.user.id === loggedUserId
                            ? request.sender?.community.labelFr
                            : request.recipient?.community.labelFr}
                        </span>
                      </div>
                    </td> */}
                    {hasCampaigns && (
                      <td className="--shrink --txt--center">
                        {request.campaign?.name ? (
                          <div className="tag--3">{request.campaign.name}</div>
                        ) : (
                          "-"
                        )}
                      </td>
                    )}
                    <td className="--shrink --txt--center">
                      {isSender(request) ? (
                        <strong>
                          {" "}
                          <DisplayPrice
                            amount={addFee(request.price, request.fee)}
                          />
                        </strong>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td className="--shrink --txt--center">
                      {!isSender(request) && request.price ? (
                        <strong>
                          <DisplayPrice amount={request.price} />
                        </strong>
                      ) : (
                        "-"
                      )}
                    </td>
                  </>
                )}
                <td className="--txt--right">
                  <div className="ui-row --right">
                    {new Date(request.booking!.endTime) < new Date() &&
                      (isSender(request)
                        ? request.senderClosureDate === null
                        : request.recipientClosureDate === null) && (
                        <Link
                          to={`/app/bookings/${request.uuid}/closure`}
                          className="btn--2 --xs"
                        >
                          Clôturer
                        </Link>
                      )}
                    {(status === AppointmentsStatus.TO_COME ||
                      status === AppointmentsStatus.ONGOING) && (
                      <Link
                        to={`${CAL_BASE_URL}/video/${request.booking?.uid}`}
                        className="btn--2 --s"
                        target="_blank"
                      >
                        <Icon name="visio" />
                      </Link>
                    )}
                    <Link
                      to={`/app/bookings/${request.uuid}`}
                      className="btn --btn--icon --chevron-right"
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* NOTE: This test is here because I think the minimal version must to have a link to the appointment page which use the full version of the component. So, the full version of the component don't need have this link. */}
      {minimal && (
        <div className="--txt--right">
          <Link to={"/app/bookings/appointments"} className="link --s">
            Voir tous mes rendez-vous
          </Link>
        </div>
      )}
    </div>
  );
};

export default AppointmentsTable;
